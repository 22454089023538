import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';
import Main from 'layouts/Main';
import Container from 'components/Container';
import Content from './Content';

const ClassAssembly8C = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main >
      <Box>
      
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Content />
            </Grid>
            <Grid item xs={12} md={4}>
           
                <Box marginBottom={4}>
                  <SidebarArticles />
                </Box>
             
              {/* <SidebarNewsletter /> */}
            </Grid>
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'alternate.main'}>
     
        {/* <Container>
          <FooterNewsletter />
        </Container> */}
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default ClassAssembly8C;
