/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
// import p1 from 'assests/Photos/ClassAssembly/8C/1.jpg'
// import p2 from 'assests/Photos/ClassAssembly/8C/2.jpg'



import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import Grid from '@mui/material/Grid';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// // import Divider from '@mui/material/Divider';
// import Avatar from '@mui/material/Avatar';
// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';

const Content = () => {
  const theme = useTheme();
 
  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/8C/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/8C/2.webp`;


  // const photos = [
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img25.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img22.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img24.jpg',
  //     rows: 1,
  //     cols: 1,
  //   },
  //   {
  //     src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
  //     rows: 1,
  //     cols: 2,
  //   },
  // ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant='h4' align='center'>
        Mattie Stepanek  
        </Typography>
        <Typography  fontSize = "x-small">
        Class: 8C <br/>
        Date: 31 October & 2 November 2022<br/>
        </Typography>
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        A special assembly was conducted on 31st October, 2022 by the students of class 8 C on the occasion of
National Unity Day or Rashtriya Ekta Diwas to pay tribute to the great Indian freedom fighter Sardar
Vallabhbhai Patel .<br/><br/>
The assembly commenced with a short poem highlighting the significance of unity and togetherness in
our lives. Thereafter, students presented a skit and mesmerising dance to instil a feeling of oneness and
to convey the message “Where there is unity, there is always victory”<br/><br/>
Yet another landmark day in the history of Karnataka ‘Rajyothsava Day’ was commemorated on 2nd
November 2022. Students of class 8 C put up a spectacular class assembly to mark the occasion.
Students spoke about the significance of the day, followed by the melodious group song. An
informative quiz was also conducted for the students. The assembly filled the young hearts with a sense
of pride and love for their state.<br/><br/>
        </Typography>
        <br/><br/>
        <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >“Unity is strength... when there is teamwork and collaboration, wonderful things can be
        achieved.”
        </Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'80%'}
            width={'80%'}
            src={p1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
      
   
        <LazyLoadImage
            height={'80%'}
            width={'80%'}
            src={p2}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
          <br/>
          </Box>
        <Box marginY={4}>
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
